<template>
    <recess-modal
        :show-modal="isModalVisible"
        @close="close"
    >
        <template slot="modal-title">
            <h3 class="qa-dashboard-info-modal-title">{{ PROVIDER.Shared.Popups.DashboardInfoPopup.Title }}</h3>
        </template>


        <template
            v-if="isPortfolioDashboard"
            slot="modal-body"
        >
            <div class="w-90">
                <p class="qa-portfolio-dashboard-info-modal-body">
                    {{PROVIDER.Shared.Popups.DashboardInfoPopup.Porfolio.FirstParagraph.PartOne}}
                    <a
                        :href="PROVIDER.Shared.Popups.DashboardInfoPopup.EdudexUrl"
                        target="_blank"
                        class="qa-portfolio-dashboard-edudex-link"
                    >{{PROVIDER.Shared.Popups.DashboardInfoPopup.EdudexUrl }}</a>. {{PROVIDER.Shared.Popups.DashboardInfoPopup.Porfolio.FirstParagraph.PartTwo}}
                    <span class="font-italic">{{PROVIDER.Shared.Popups.DashboardInfoPopup.Porfolio.FirstParagraph.PartThree}}</span>.
                </p>
                <p>
                    {{ PROVIDER.Shared.Popups.DashboardInfoPopup.Porfolio.SecondParagraph.PartOne }}
                    <span class="font-italic">{{ PROVIDER.Shared.Popups.DashboardInfoPopup.Porfolio.SecondParagraph.PartTwo }}</span> {{ PROVIDER.Shared.Popups.DashboardInfoPopup.Porfolio.SecondParagraph.PartThree }}
                </p>
            </div>
        </template>




        <template
            v-if="isOrderDashboard"
            slot="modal-body"
        >
            <div class="w-90">
                <p class="qa-order-dashboard-info-modal-body">
                    {{ PROVIDER.Shared.Popups.DashboardInfoPopup.Orders.FirstParagraph.PartOne }}
                    <span
                        class="font-italic"
                    >{{ PROVIDER.Shared.Popups.DashboardInfoPopup.Orders.FirstParagraph.PartTwo }}</span>. {{ PROVIDER.Shared.Popups.DashboardInfoPopup.Orders.FirstParagraph.PartThree }}.
                </p>
                <p>{{ PROVIDER.Shared.Popups.DashboardInfoPopup.Orders.SecondParagraph.PartOne }}</p>
            </div>
        </template>



        <template v-if="isEdudexDashboard"
            slot="modal-body"
        >
            <div class="w-90">
                <p> {{ PROVIDER.Shared.Popups.DashboardInfoPopup.Edudex.FirstParagraph.PartOne }}</p>

                {{ PROVIDER.Portfolio.EdudexFeed.MoreInfoText }}
                <a :href="PROVIDER.Portfolio.EdudexFeed.EdudexSupportUrl" 
                    target="_blank" 
                    class="qa-edudex-edudex-support-url"
                >
                    {{ PROVIDER.Portfolio.EdudexFeed.EdudexSupportUrlText }}
                </a>
                {{ PROVIDER.Portfolio.EdudexFeed.EdudexSupportPartOne }}

                {{ PROVIDER.Portfolio.EdudexFeed.EdudexSupportPartTwo }}

                <p> {{ PROVIDER.Shared.Popups.DashboardInfoPopup.Edudex.SecondParagraph.PartOne }}</p>

                <p>
                    {{ PROVIDER.Shared.Popups.DashboardInfoPopup.Edudex.ThirdParagraph.PartOne }}
                    <ul class="pl-4">
                        <li>{{ PROVIDER.Shared.Popups.DashboardInfoPopup.Edudex.ThirdParagraph.FirstListItem }}</li>
                        <li>{{ PROVIDER.Shared.Popups.DashboardInfoPopup.Edudex.ThirdParagraph.SecondListItem }}</li>
                        <li>{{ PROVIDER.Shared.Popups.DashboardInfoPopup.Edudex.ThirdParagraph.ThirdistItem }}</li>
                    </ul>
                </p>             
                <p>
                    {{ PROVIDER.Portfolio.EdudexFeed.IntroductionText }} 
                    <a :href="PROVIDER.Portfolio.EdudexFeed.ValidatorUrl" 
                        target="_blank" 
                        class="qa-edudex-validator-link"
                    >
                        {{ PROVIDER.Portfolio.EdudexFeed.ValidatorUrlText }}</a>
                </p>                
            </div>
        </template>
    </recess-modal>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

export default {
    name: 'DashboardInfoPopUpComponent',
    props: {
        isModalVisible: {
            type: Boolean,
            default: false
        },
        dashboardType: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            PROVIDER
        }
    },
    computed: {
        isPortfolioDashboard() {
            return this.dashboardType === 'portfolio'
        },
        isOrderDashboard() {
            return this.dashboardType === 'order'
        },
        isEdudexDashboard() {
            return this.dashboardType === 'edudex'
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>